import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MsAuthState } from '@ms/angular-workspace/dist/core';

@Injectable()
export class LenderGuard implements CanActivate {
  private _isLender$: Observable<boolean> = inject(Store).select(
    MsAuthState.hasOrganizationData
  );

  constructor(private router: Router) {}

  canActivate() {
    return this._isLender$.pipe(
      tap((isLender) => {
        if (!isLender) {
          this.router.navigate(['']);
        }
      })
    );
  }
}
